<template>
  <v-content>
    <router-view :key="$route.path"/>
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
    },
  }
</script>
